/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React, {
  useState,
  useEffect,
  useCallback
} from "react";
import PropTypes from "prop-types";

import {
  Analytics,
  checkIsWebView
} from "utils";
import {
  RudderStackEvent
} from "types";

import PartnerKycSuccessScreen from "./PartnerKycSuccessScreen";
import env from "env";

const propTypes = {
  kycData: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
  skipPartnerStep: PropTypes.func.isRequired
};

const PartnerKycSuccessScreenContainer = ({
  kycData,
  updateStatus,
  skipPartnerStep,
  handleAnaylticsEvent
}) => {
  const [timer, setTimer] = useState(5);
  const [isRedirectLoading, setRedirectLoading] = useState(false);

  const isSubflowPresent = kycData.hasSubFlow && !!kycData.subFlow?.redirectUrl?.length;
  const skippingSuccessScreen = kycData.hasSubFlow && !!kycData.subFlow?.additionalData?.skipSuccessScreen;

  useEffect(() => {
    if (!isSubflowPresent) {
      if (timer === 0) {
        handleRedirection();
      }
      const intervalId = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    } else {
      Analytics.trackForRudder(RudderStackEvent.KYC_COMPLETED);
    }

    if (skippingSuccessScreen) {
      handleSubFlow();
    }
  }, [timer, kycData]);

  const redirectLink = window.sessionStorage.getItem("redirectTo");
  const newAppVersion = window.sessionStorage.getItem("newAppVersion");

  const handleRedirection = useCallback(() => {
    let empanelmentLink;
    let appRedirectionLink;
    if(redirectLink === "null"){
      empanelmentLink = `${env.PARTNER_DASHBOARD_URL}/profile`;
    } else {
      empanelmentLink=redirectLink;
      appRedirectionLink= redirectLink;
    }
    if (redirectLink !== "null") {
      if (isSubflowPresent) {
        Analytics.trackForRudder(RudderStackEvent.KYC_SUBFLOW_SKIP);
      }
      setRedirectLoading(true);
      setTimeout(() => {                         //Added for Loading state when redirecting automatically
      if(kycData?.flow === "PARTNER_GST"){
        if(checkIsWebView() && newAppVersion === "true"){
          window.location.replace(redirectLink); //Redirecting on app for new version 4.0.5 when the redirection link is present
          } else {
          if(checkIsWebView()){
            window.location.replace(`${env.APP_LINK_URL}/empanelment`);;
          } else {
            window.location.replace(empanelmentLink);
          }
        }
      } else {
        window.location.replace(redirectLink);
      }
      }, 1000);
    } else if(kycData?.flow === "PARTNER_GST") {
      if (checkIsWebView()) {
        window.location.replace(`${env.APP_LINK_URL}/empanelment`);
      } else {
        window.location.replace(empanelmentLink);
      }
    }
  }, [redirectLink, isSubflowPresent]);

  const handleSubFlow = useCallback(() => {
    if (kycData.hasSubFlow) {
      setRedirectLoading(true);
      let targetUrl = kycData.subFlow?.redirectUrl;
        let queryParams = "";        
        if(newAppVersion === "true"){
          queryParams += `&new_app_version=true`;
        }
        if(redirectLink !== "null"){
          queryParams += `&redirect_to=${redirectLink}`;
        }
        targetUrl += `${queryParams}`;

      setTimeout(() => {
        window.location.replace(targetUrl);
      }, 1000);

    } else {
      handleRedirection();
    }
  }, [kycData]);

  return (
    <PartnerKycSuccessScreen
      kycData={kycData}
      handleSubFlow={handleSubFlow}
      handleRedirection={handleRedirection}
      isRedirectLoading={isRedirectLoading}
      isSubflowPresent={isSubflowPresent}
      timer={timer}
      skipPartnerStep={skipPartnerStep}
      skippingSuccessScreen={skippingSuccessScreen}
    />
  );
};

PartnerKycSuccessScreenContainer.propTypes = propTypes;

export default PartnerKycSuccessScreenContainer;
